import React, { useEffect, useState } from 'react';
import { find } from 'qcp-js-ui-core/utils/service-registry';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { requestStatus } from 'qcp-js-ui-core/constants';
import { getModel } from 'qcp-js-ui-core/models';
import { displayModal } from '../store/actions/modal';
import {
  updateViewReference,
  updateViewState,
} from 'qcp-js-ui-core/store/actions/rms';

import { buildView } from 'qcp-js-ui-core/component-logic/buildView';
import FileUpload from './modal/FileUpload';
import Confirm from './modal/Confirm';
import SchemaView from './modal/SchemaView';
import RouteView from './modal/RouteView';
import NewServiceWorker from './modal/NewServiceWorker';
import { Row } from './common';
import styled, { css } from 'styled-components';

const none = () => <div />;
const uploadFile = ({ closeModal }) => <FileUpload closeModal={closeModal} />;
const confirmModal = () => <Confirm />;
const schemaView = ({ closeModal }) => <SchemaView closeModal={closeModal} />;
const routeView = ({ closeModal }) => <RouteView closeModal={closeModal} />;
const newServiceWorker = () => <NewServiceWorker />;

const modals = {
  none,
  uploadFile,
  confirmModal,
  schemaView,
  routeView,
  newServiceWorker,
};

const ModalContainer = ({isVisible, dispatch}) => {
  const closeModal = () => {
    isVisible && dispatch(displayModal('none', {}));
  };
  const [newWorker, setNewWorker] = useState();
  const storeModalType = useSelector(state => state.modal?.modalType || 'none');
  const modalType = newWorker || storeModalType;

  useEffect(() => {
    const serviceWorkerUpdateNotfication = new BroadcastChannel("serviceWorkerUpdateNotification");
    serviceWorkerUpdateNotfication.addEventListener('message', (message) => {
      if(message?.data == 'NEW_SERVICE_WORKER' && newWorker != 'newServiceWorker') {
          setNewWorker('newServiceWorker')
          serviceWorkerUpdateNotfication.close()
        }
    });
    return () => { serviceWorkerUpdateNotfication.close() }
  }, [])
  if(!isVisible) { return <div />; }
  
  const constructedModals = {};
  Object.keys(modals).map((key) => {
    constructedModals[key] = modals[key]({ closeModal });
  });
  return constructedModals[modalType];
};

const ViewComponent = ({
  route,
  baseView = false,
  setShowLoading = (...args) => console.log('setShowLoading:', ...args),
  enabled
}) => {
  const dispatch = useDispatch();
  const viewJson = useSelector(state => state.rms.schemas.views);
  const actionStatus = useSelector(state => state.rms?.actionStatus?.status);

  const { key, quin } = route || {};

  const modelForId = useSelector(state => getModel(quin, state.rms), shallowEqual);

  const navigation = find('navigation')();
  const name = viewJson?.byPath?.[key]?.name;

  const { contextType, fallbackContextAction, viewReferences, viewState } =
viewJson?.[name] ?? {};
  const isInProgress = actionStatus === requestStatus.PROCESSING;

  useEffect(() => {
    if (viewReferences) {
      dispatch(updateViewReference(quin, viewReferences));
    }
  }, [viewReferences]);

  useEffect(() => {
    if (viewState) {
      dispatch(updateViewState(quin, viewState));
    }
  }, [viewState]);

  const getDeviceType = () => '';

  const model = quin ? modelForId : {};
  if(!quin) { return <div />; }

  const rowDivisions = viewJson[name]?.rowDivisions ? viewJson[name]?.rowDivisions : 4;
  let view = (viewJson?.[name] ? buildView({
    model,
    isInProgress,
    json: viewJson[name],
    navigation,
    setShowLoading,
    id: quin,
    dispatch,
    viewJson,
    contextType,
    getDeviceType,
    fallbackContextAction,
  }) : null);

  return (
    <Wrapper enabled={enabled ?? true} baseView={baseView} className={'main'} hasDivisions={rowDivisions}>
      <ModalContainer isVisible={baseView} dispatch={dispatch}/>{view}
    </Wrapper>
  );
};

export default ViewComponent;

const Wrapper = styled(Row)`
/*background: ${({ baseView }) => !baseView ? 'white' : 'transparent'};*/
/*max-width: 1200px;*/
/*margin: ${({ baseView }) => !baseView ? 'auto' : '0 -15px'};*/
${({ enabled }) =>
    !enabled &&
css`
pointer-events: none;
`}
${({ hasDivisions }) => hasDivisions !== 4 && `width: ${100/hasDivisions}`};

/* &:nth-of-type(1) {
padding: 0;
}
&:nth-of-type(2) {
padding: ${({ baseView }) => !baseView ? '15px' : '0'};
margin: 0 -15px;
} */
`;
