import { useSelector } from 'react-redux';
import { initialize, register } from 'qcp-js-ui-core/utils/service-registry';
import schema from 'qcp-js-ui-core/configs/service-registry';

import CognitoSession from './utils/cognito-session';
import store from './store';
import hookNavigation from './implementation/hookNavigation';
import setJwtToken from './implementation/setJwtToken';
import Navigation from './implementation/navigation';
import ViewComponentFactory from './components/ViewComponentFactory';
import { displayModal, hideModal } from './store/actions/modal';
import SharedStorage from './implementation/s3';
import { setNotification } from './store/actions/notification';
import { AsyncStorage } from './implementation/asyncStorage'
import { handleBinding, getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { requestStatus } from 'qcp-js-ui-core/constants';
import { updateActionStatus } from 'qcp-js-ui-core/store/actions/rms';

// TODO: implement what these log and delete this method
const logCalls = (object, ...names) =>
  names.forEach((name) => object[name] = (...args) => console.log(name, args));

const BackgroundTasks = {};
logCalls(
  BackgroundTasks,
  'configure',
  'finish',
  'stop',
  'start',
  'status',
  'getStatusCodes',
);

const FileSystem = {};
logCalls(
  FileSystem,
  'getMediaLocation',
  'uriExistsAsync',
  'createDirectoryAsync',
  'writeAsStringAsync',
  'getBase64Encoding',
  'getDocumentDirectory',
  'writeToFileAsync',
  'readFromFileAsync',
);

const Alert = {
  alert: (message) => store.dispatch(setNotification(message)),
};

const modalNavigation = async (args) => {
  const { dispatch } = args;
  await dispatch(displayModal('routeView', args));
};

const tabNavigation = ({ link, quin }) => {
  const path = (link.href.startsWith('/') ? '' : '/') + link.href.replace('{quin}', quin);
  let tries = 0;
  const checkForPath = () => {
    const tabPath = store.getState().rms[quin];
    if(!tabPath?.quin) {
        setTimeout(checkForPath, 1000);
        tries++;
        return false;
    }

    let newTabUrl = location.origin + path;
    if(tabPath?.fileType == 'pdf') {
        const urlBinding = handleBinding({propertyName: 'url'}, tabPath.quin);
        const pdfURL = getValueFromBindingObject(urlBinding, store.getState())
        const retryStatuses = [ 403, 404, 503 ];
        const timeout = Date.now() + 60000;
        (async () => {
            await store.dispatch(updateActionStatus(tabPath?.quin, requestStatus.PROCESSING));
            while(Date.now() < timeout) {
                await sleep(1000);
                const response = await fetch(pdfURL);
                if(!retryStatuses.includes(response.status)) {
                    if(response.status === 200) {
                        tries = 0;
                        await store.dispatch(updateActionStatus(tabPath?.quin, requestStatus.SUCCESS));
                        window.open(pdfURL, '_blank');
                        return;
                    }
                }
            }
        })();
    } else {
        tries = 0;
        window.open(newTabUrl, '_blank');
    }
  }
  if(tries < 4) {
    checkForPath();
  } else {
    store.dispatch(setNotification(`Error: Failed to load PDF. Please try again.`));
  }
  
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

window.attachmentManager = {
  selectAndUpload: (attachmentInfo) => store.dispatch(displayModal('uploadFile', attachmentInfo)),
  download: (args) => {
    const { url } = args;
    const link = document.createElement('a');
    link.setAttribute('download', '');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
};

const putUpModal = (modaltype,message,continueText,cancelText) => {
    store.dispatch(displayModal(modaltype,{message,continueText,cancelText}));
};

const StackActions = {
  push: (key, name, id) => ({
    name: key,
    payload: { name, id },
    type: 'PUSH',
  }),
};

initialize(schema);

register('navigation-behaviours', { hookNavigation });
register('authentication', { setJwtToken });
register('prepare-request', { prepareHeaders: () => null });
register('hyperSchemaAction', { modalNavigation, tabNavigation });
register('shared-storage', SharedStorage);
register('stack-actions', StackActions);
register('cognito-session', CognitoSession);
register('utils/file-system', FileSystem);
register('utils/background-tasks', BackgroundTasks);
register('utils/file-system/asyncStorage', AsyncStorage);
register('components/ViewComponentFactory', ViewComponentFactory);
register('alert', Alert);
register('navigation', () => Navigation);
register('store', () => store);
register('featured-fetch', {featuredFetch: (...args) => fetch(...args)});
register('modal', { hide: () => store.dispatch(hideModal()), putUpModal});
