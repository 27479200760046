import React, { useEffect, useState } from 'react';

import Icon from '../shared/Icon';
import Modal from '../high-order/Modal';
import styled from 'styled-components';
import { unregister } from '../../serviceWorkerControl';

const reload = () => { location.reload() }

const NewServiceWorker = () => {
  const [timeLeft, setTimeLeft] = useState(5);

  const doNextTime = () => {
    setTimeLeft(timeLeft - 1);
  }

  useEffect(() => {
    unregister();
  }, []);

  useEffect(() => {
    if(timeLeft < 1) {
      location.reload();
      return;
    }
    setTimeout(doNextTime, 1000);
  }, [timeLeft]);


  return (
    <StyledModal title={'New Service Worker Detected'} visible={true} onClose={reload}>
      <StyledIcon name="refresh-cw" size="90" />
      <p>
        A new service worker is available.  This application will
        restart in {timeLeft} seconds to start using it.
      </p>
    </StyledModal>
  );
};

export default NewServiceWorker;
const StyledIcon = styled(Icon)`
  margin: auto;
`
const StyledModal = styled(Modal)`
height: 100px;
width: 500px;
p {
  font-size: 1.5em;
  text-align: center;
  padding: 30px;
  position: relative;
  top: 10px;
}
&.cancel {
    background: #4d8cc0;

    &:hover {
        opacity: .75;
    }
}
`