import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import theme from '../theme';
import logo from '../../../static/quicket-new@4.png';

const ErrorrmsStartup = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <ErrorRms>
            <ErrorImageLogo>
                <ErrorImage src={logo} />
                <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                    <ErrorIcon color={theme.sideBarActive} name="alert-triangle" font="Feather" size={30} />
                </span>
            </ErrorImageLogo>
            {showTooltip &&
                <ErrorHover>
                    {props.value}
                </ErrorHover>
            }
            <ErrorContent>
                {props.value}
            </ErrorContent>
        </ErrorRms>
    )
};

export default ErrorrmsStartup;

const ErrorRms = styled.div`
border-radius: 12px;
border: 4px solid ${theme.sideBarActive};
flex-direction: column;
justify-content: space-between;
left: 30%;
top: 30%;
position: absolute;
width: 40%;
height: 250px;
`;

const ErrorHover = styled.div`
position: absolute;
width: 220px;
color: white;
font-size: 12px;
background-color: #192733;
border-radius: 10px;
padding: 10px 15px 10px 15px;
left: 59.4%;
top: 20%;
`;

const ErrorImageLogo = styled.div`
background-color: #e9eff0;
border-radius: 12px 12px 0px 0px;
`;

const ErrorContent = styled.div`
color: red;
padding-top: 12%;
text-align: center;
`;

const ErrorIcon = styled(Icon)`
float: right;
padding-right: 32px;
padding-top: 16px;
`;

const ErrorImage = styled.img`
height: 50px;
margin: 10px auto 10px auto;
`;
