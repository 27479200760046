import React, { memo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconDropdown from '../shared/IconDropdown';
import Icon from '../shared/Icon';
import styled, { ThemeContext } from 'styled-components';
import TopNavigationSystem from '../../TopNavigationSystem';
import Toggle from '../Toggle';
import theme from '../theme';
import { useDarkMode } from '../useDarkMode'
import { doesServerKnow } from 'qcp-js-ui-core/models';


const Header = ({ secondary, options, handleThemeChange }) => {
  const userQuin = useSelector((state) => state.rms?.session?.user);
  const userSession = useSelector((state) => state.rms?.[userQuin] ? state.rms[userQuin] : {});
  const actionStatus = useSelector(state => state.rms?.actionStatus?.status);
  const [user, setUser] = useState({});
  const [themee, themeToggler] = useDarkMode();
  const themeContext = useContext(ThemeContext);
  const currentRoutes = useSelector(state => state.navigator?.currentRoute);
  const currentRoute = currentRoutes[currentRoutes.length-1];
  const isDirty = useSelector(state => doesServerKnow(currentRoute?.quin,state.rms));
  const statusIconList =
    [
      {isIcon: false, IconName: "git-merge", IconDescription: "Having Merge Conflicts"},
      {isIcon: false, IconName: "cloud-off", IconDescription: "Being Offline"},
      {isIcon: isDirty, IconName: "upload-cloud", IconDescription: "Having Changes"},
    ]

  useEffect(() => {
    if(userSession?.firstName && userSession?.lastName) {
      setUser(userSession);
    }
    handleThemeChange(themee);
  }, [actionStatus, themee, userSession]);
  
  return (
    <Wrapper className={'header-wrapper'} activeTheme={themeContext}>
      <TopNavigationSystem />
      <StatusIcons iconList={statusIconList} />
      <Toggle theme={themee} toggleTheme={themeToggler} />
      {secondary && <Image src={secondary} />}
      {!secondary && (
        <Icons>
          <IconWrapper>
            <IconDropdown
              key={1}
              color={theme.sideBarBackground}
              //options={options}
              //newOptions={newOptions}
              name="bell"
              font="Feather"
              size={16}
            />
          </IconWrapper>
          {Object.keys(user).length > 0 &&
            <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
          }
          <IconDropdown key={2} options={options} color={theme.white} name="chevron-down" font="Feather" size={20} />
        </Icons>
      )}
    </Wrapper>
  );
};

Header.displayName = 'Header';

Header.defaultProps = {
  secondary: '',
  options: [],
  newOptions: 0,
};

Header.propTypes = {
  secondary: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  newOptions: PropTypes.number,
};

export default memo(Header);

const StatusIcons = ({iconList}) => {
  const statusIconList = iconList.filter(icon => !!icon.isIcon).map((icon, index) => (
    <StatusIconWrapper key={index} title = {icon.IconDescription}>
      <StatusIcon color={theme.white} name={icon.IconName} font="Feather" size={18} />
    </StatusIconWrapper>)
  );
  const listItemCount1 = statusIconList.length > 2 ? Math.ceil(statusIconList.length/2) : statusIconList.length;
  const list1 = statusIconList.slice(0, listItemCount1);
  const list2 = statusIconList.slice(listItemCount1);
  return (
      <IconStatus rowCount={list2.length ? 2 : 1}>
      <StyledStatusIcons forceCentering={false}>{list1}</StyledStatusIcons>
      <StyledStatusIcons forceCentering={list2.length % 2}>{list2}</StyledStatusIcons>
      </IconStatus>
  )
}
const Text = styled.span`
color: ${theme.greyBox};
font-size: .75rem;
font-weight: 300;
letter-spacing: 1px;
margin: 0 10px 0 5px;
`;

const Image = styled.img`
height: ${theme.headerHeight / 2}px;
`;

Image.displayName = 'Image';

const Logo = styled.div`
justify-content: space-evenly;
width: 110px;
align-items: center;
`;

Logo.displayName = 'Logo';

const LogoText = styled.h1`
font-family: inherit;
font-family: 'Avenir';
font-size: 16px;
font-weight: 500;
height: 21px;
letter-spacing: 0.5px;
margin: 0;
`;

LogoText.displayName = 'LogoText';

const IconWrapper = styled.div`
background: white;
border: 1px solid ${theme.grey};
border-radius: 4px;
padding: 4px;
`;

const Icons = styled.div`
justify-content: space-evenly;
align-items: center;
margin-right: 10px;
padding-bottom: 7px;
`;

const StyledStatusIcons = styled.div`
position: relative;
left: ${({forceCentering}) => forceCentering ? 14 : 0}px;
display: flex;
align-items: center;
gap: 6px;
margin-left: 4px;
`;

const Wrapper = styled.div`
justify-content: space-between;
padding: 0;
position: sticky;
top: 0;
z-index: 9999;
background-color: ${({ activeTheme }) => activeTheme.headerBackground};
border-bottom: 1px solid ${theme.primary};
padding: 10px 0 3px 0;
height: 30px;
`;

const UploadableIcon = styled(Icon)`
padding: 5px 7px;
`;

const StatusIcon = styled(Icon)`
margin: 1px;
padding: 1px;
&:hover{
  cursor: pointer;
}
`
const IconStatus = styled.div`
position: relative;
top: ${({rowCount}) => rowCount < 2 ? 2 : -10}px;
display: flex;
flex-direction: column;
padding: auto 4px;
margin-right: 6px;
`

const StatusIconWrapper = styled.div`
${({ title }) =>
`
&:before {
    content: "${title}";
    font-size: .50rem;
    text-transform: initial;
    font-family: ${theme.baseFont};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    width: 0px;
    height: 100%;
    transition: all 0.2s linear 0s;
    text-align: center;
    display: none;
  }
    &:hover {
        span {
            display: block;
            text-indent: -30px;
        }
    }
`}
`
